import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export function ShowcasedAssignment(assignment) {
    console.log("Showcased Assignment: ", assignment);
    return (
        <Container className="d-flex justify-content-center h-100 bg-dark text-dark" fluid>
            <div
                style={{
                    pointerEvents: "all",
                    zIndex: 9999,
                    height: "80px",
                    maxWidth: "1200px", // Increased maxWidth to make the table container larger
                }}
                className="h-100 d-flex flex-column justify-content-center align-items-center"
            >
                <Row className="bg-white rounded w-200">
                    <Col className="p-3">
                        <h5 className="text-center mb-4">Student Spaces for {assignment.assignment.title}</h5> 
                        {assignment.assignment.classroom_set?.length > 0 ? (
                            <Table hover size="lg" className="table-responsive">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Visit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assignment.assignment.classroom_set?.map((room, index) => (
                                        <tr key={index}>
                                            <td>{room.title}</td>
                                            <td>
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <Button
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={`/${room.hub_id}/`}
                                                        size="sm"
                                                        variant="success"
                                                    >
                                                        Visit Student Space
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <Alert variant="warning">
                                <p className="">
                                    This assignment has no student spaces to showcase
                                </p>
                            </Alert>
                        )}
                    </Col>
                </Row>
            </div>
        </Container>

    );
}